<template>
  <div class="centered px-2">
    <button class="w-100 webshop-button"
               type="primary"
               @click="openLink">
      {{ $t('post.go_webshop_btn') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'go-webshop-button',
  props: {
    url: {
      type: String
    },
  },
  methods: {
    openLink() {
      return window.open(this.url, '_blank');
    }
  }
}
</script>
