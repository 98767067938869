<template>
  <el-form
    ref="form"
    :model="form"
    class="post-step__container content-step"
  >
    <el-input
      class="post-step__title"
      :placeholder="`${titleNumber}. ${$t('post.steps.content')}`"
      disabled
    />

    <el-form-item prop="title" :rules="rules.title" class="flex-nowrap mb-3.5">
      <div class="d-flex w-100 align-items-center">
        <el-avatar :src="USER.avatar_url" shape="circle"/>
        <el-input
          v-model="form.title"
          :placeholder="$t('post.placeholders.title', {promo: lowerFirstsSubtypeTitle})"
        />
      </div>
    </el-form-item>

    <el-form-item prop="content" :rules="rules.content" class="position-relative">
      <el-input
        ref="content"
        v-model="form.content"
        type="textarea"
        :placeholder="contentPlaceholder"
        :autosize="{ minRows: 5, maxRows: 20 }"
      />
      <emoji-picker :placement="xsOnly ? 'left' : 'bottom'" @select="selectEmoji($event)"/>
    </el-form-item>

    <el-form-item
      class="mb-2.25"
      v-if="isDiscount"
      prop="discount_code"
      :label="$t('post.discount_code')"
      :rules="rules.discount_code"
    >
      <el-input v-model="form.discount_code"/>
    </el-form-item>

    <div v-if="isDiscount" class="not-started">
      <el-checkbox v-model="form.not_started" :label="$t('post.not_started')" class="mb-1.5"/>

      <div v-if="form.not_started" class="justify-content-md-between d-flex flex-wrap">
        <el-form-item :label="$t('post.start_date')" prop="started_at" :rules="rules.started_at">
          <el-date-picker
            v-model="form.started_at"
            :disabled-date="disableDates"
            :class="{'me-1.5': !xsOnly}"
            placeholder="dd/mm/jj"
            size="small"
            type="date"
          />
        </el-form-item>

        <el-form-item class="expired_at" prop="expired_at" :label="$t('post.end_date')" :rules="rules.expired_at">
          <el-date-picker
            v-model="form.expired_at"
            :disabled-date="disableExpiredAtDates"
            placeholder="dd/mm/jj"
            size="small"
            type="date"
          />
        </el-form-item>
      </div>
    </div>

    <el-form-item v-if="isDiscount" prop="webshop_id" :rules="rules.webshop_id" :label="$t('post.webshop')">
      <el-select
        class="w-100"
        v-model="form.webshop_id"
        :remote-method="searchWebshop"
        :loading="loadingWebshop"
        :placeholder="' '"
        clearable
        filterable
        remote
      >
        <el-option
          v-for="item in form.webshops"
          :key="item.id"
          :label="item.translation.title"
          :value="item.id"
        />
      </el-select>
    </el-form-item>

    <el-form-item
      v-if="isDiscount && !form.not_started"
      prop="expired_at"
      class="mt-2.25"
      :label="$t('post.end_date')"
      :rules="rules.expired_at"
    >
      <el-date-picker
        v-model="form.expired_at"
        :disabled-date="disableExpiredAtDates"
        type="date"
        placeholder="dd/mm/jj"
        size="small"
      />
    </el-form-item>
  </el-form>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import EmojiPicker from '@/components/feed/post/EmojiPicker.vue';
import {step} from '@/mixins/post/step';

export default {
  name: 'ContentStep',
  components: {EmojiPicker},
  mixins: [step],
  computed: {
    ...mapGetters('auth', ['USER']),
    ...mapState('promo', {
      form: ({form}) => form,
      type: ({form}) => form.type,
      subtype: ({form}) => form.subtype,
      started_at: ({form}) => form.started_at,
      expired_at: ({form}) => form.expired_at
    }),
    /**
     * Title placeholder lower First
     * @returns {string}
     */
    lowerFirstsSubtypeTitle() {
      return _.lowerFirst(this.subtype.translation.title);
    },
    /**
     * Is discount
     * @returns {boolean}
     */
    isDiscount() {
      return this.$consts.KIND.DISCOUNT === _.get(this.subtype, 'kind', null);
    },
    /**
     * Content placeholder
     * @returns {string}
     */
    contentPlaceholder() {
      let text = '';

      switch (this.type.id) {
        case this.$consts.POST_TYPES.REPORT:
          text = this.$t('post.placeholders.content.report')
          break;
        case this.$consts.POST_TYPES.QUESTION:
          text = this.$t('post.placeholders.content.question', {name: this.USER.fname})
          break;
        case this.$consts.POST_TYPES.BARGAIN:
          text = this.$t('post.placeholders.content.bargain', {name: this.USER.fname})
      }

      return text
    }
  },
  data() {
    return {
      rules: {
        title: [
          {required: true, message: this.$t('post.errors.title')}
        ],
        content: [
          {required: true, message: this.$t('post.errors.content')}
        ],
        discount_code: [
          {required: true, message: this.$t('post.errors.discount_code')}
        ],
        webshop_id: [
          {required: true, message: this.$t('post.errors.webshop')}
        ],
        expired_at: [
          {required: true, type: 'date', message: this.$t('post.errors.expired_at')},
          {validator: this.dateValidator}
        ],
        started_at: [
          {required: true, type: 'date', message: this.$t('post.errors.started_at')},
          {validator: this.dateValidator}
        ],
      },
      loadingWebshop: false,
    }
  },
  methods: {
    /**
     * Date validator
     * @param rule
     * @param value
     * @param callback
     */
    dateValidator(rule, value, callback) {
      if (!value) {
        callback();
      }

      if (!(value instanceof Date)) {
        callback(this.$t('post.errors.format'));
      }

      if (
        this.expired_at &&
        this.started_at &&
        this.started_at.getTime() > this.expired_at.getTime()
      ) {
        callback(this.$t('post.errors.date_range'));
      }

      callback()
    },
    /**
     * Disable all dates before date in second param
     * @param time
     * @param date
     * @returns {boolean}
     */
    disableDates(time, date = Date.now()) {
      return time.getTime() < date - 24 * 60 * 60 * 1000;
    },
    /**
     * @param time
     * @returns {boolean}
     */
    disableExpiredAtDates(time) {
      return this.started_at
        ? this.disableDates(time, new Date(this.started_at))
        : this.disableDates(time)
    },
    /**
     * Search webshops
     * @param query
     */
    searchWebshop(query) {
      if (query === '') {
        this.updateForm({webshops: []})
        return;
      }

      this.loadingWebshop = true;
      axios.get('/webshops/search?q=' + encodeURIComponent(query)).then((response) => {
        this.updateForm({webshops: response.data.data});
        this.loadingWebshop = false;
      });
    },
    /**
     * Select emoji
     * @param emoji
     */
    selectEmoji(emoji) {
      const insertText = emoji.native;
      if (!insertText.length) return;

      const textarea = this.$refs.content.$refs.textarea;
      const sentence = textarea.value;
      const len = sentence.length;
      let pos = textarea.selectionStart;
      if (pos === undefined) {
        pos = 0;
      }

      const before = sentence.substr(0, pos);
      const after = sentence.substr(pos, len);

      this.form.content = before + insertText + after;

      this.$nextTick().then(() => {
        textarea.selectionStart = pos + insertText.length;
      });
    },
    /**
     * Validation
     * @returns {boolean}
     */
    async validate() {
      let result = false;

      await this.$refs.form.validate((isValid) => result = isValid)

      this.$emit('update', {titlesCount: 1})

      return result
    }
  }
}
</script>
