<template>
    <span @click="openLink" class="color-primary cursor-pointer span-link">
         <slot></slot>
    </span>
</template>

<script>
export default {
  name: 'span-link',
  props: {
    href: {
      type: String
    },
    target_promo: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: '_self'
    },
    rel: {
      type: String,
      default: 'nofollow'
    }
  },
  methods: {
    openLink() {
      let target = this.href.includes('http') ? '_blank' : '_self';
      if (!_.isEmpty(this.target_promo)) {
        target = this.target_promo;
      }

      return window.open(this.href, target);
    }
  }
}
</script>
