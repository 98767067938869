
export default {
    namespaced: true,
    state: {
        form: {
            email: '',
            password: '',
            g_recaptcha: '',
        },
        user: {},
        locale: cookies.get('locale') || process.env.VUE_APP_FALLBACK_LOCALE,
        token: cookies.get('auth-token') || '',
        include: 'account,socialAccounts',
        i18n: null,
    },

    getters: {
        AUTH_TOKEN(state) {
            return state.token;
        },
        USER(state) {
            return state.user;
        },
        GUEST(state) {
            return _.isEmpty(state.user);
        },
        LOCALE(state) {
            return _.get(state, 'locale');
        },
        I18N(state) {
            return state.i18n;
        },
    },

    actions: {
        async FETCH_USER({state, commit}) {
            return await axios.get('/me', {params: {include: state.include}}).then((response) => {
                commit('SET_USER', response.data.data);
                commit('SET_LOCALE', response.data.data.locale);
            });
        },

        async LOGOUT() {
            window.open('/logout', '_self');
        },

        async UPDATE_TOKEN({commit}, token = '') {
            await cookies.set('auth-token', token);
            await commit('SET_TOKEN', token);
            window.location.reload();
        },

        async UPDATE_USER({state, commit}, fields) {
            return axios.put('/me', _.merge(fields, {include: state.include})).then((response) => {
                commit('SET_USER', response.data.data);
            });
        },

        async UPDATE_SETTINGS({state, commit}, fields) {
            return axios.put('/me/settings', _.merge(fields, {include: state.include})).then((response) => {
                commit('SET_USER', response.data.data);
            });
        },

        async UPDATE_LOCALE({state, commit, dispatch}, newLocale) {
            if (!_.isEmpty(state.user)) {
                await dispatch('UPDATE_USER', {locale: newLocale});
            }
            await commit('SET_LOCALE', newLocale);
        },

        async UPDATE_I18N({state, commit, dispatch}, value) {
            await commit('SET_I18N', value);
        },
    },

    mutations: {
        SET_USER(state, user) {
            return (state.user = user);
        },

        SET_TOKEN(state, token) {
            return (state.token = token);
        },

        SET_LOCALE(state, locale) {
            cookies.set('locale', locale, '1y')
            if (localStorage.getItem('VueAppLanguage') !== locale) {
                localStorage.setItem('VueAppLanguage', locale);
            }
            state.locale = locale;
            return (state.i18n.locale = locale);
        },

        SET_I18N(state, value) {
            return (state.i18n = value);
        },
    }
}
