<template>
  <el-dropdown
    ref="dropdown"
    :placement="'bottom-end'"
    popper-class="notification-dropdown__popper"
    trigger="click"
    :disabled="GUEST"
    @visible-change="showNotifications"
    class="menu-dropdown notification-dropdown"
  >
    <el-badge style="z-index: 5" :value="freshNotificationsCount" :hidden="freshNotificationsCount <= 0">
      <i class="icon-bell-4"/>
    </el-badge>

    <template v-slot:dropdown>
      <el-dropdown-menu class="notification-dropdown__menu py-2">
        <div class="w-100">
          <div class="read-notifications mt-2">
            <div class="notification-dropdown__header" v-t="'header.menu.notifications'"/>
          </div>

          <el-divider class="my-2"/>

          <template v-for="(notification, i) in NOTIFICATIONS.slice(0, 4)" :key="i">
            <div class="notification mx-2">
              <div class="notification__inner pe-5" :class="{'read': notification.read_at}">
                <div class="d-flex justify-content-start align-items-center" @click="clickNotification(notification)">
                  <div class="notification__avatar">
                    <el-avatar :src="notification.author_avatar" shape="circle"/>
                  </div>
                  <div>
                    <div class="notification__content" v-text="notification.content"/>
                    <div class="notification__time mt-2" v-text="notification.time_ago"/>
                  </div>
                </div>
                <i class="icon-close-4 notification-close" @click="DELETE_NOTIFICATION(notification.id)"/>
              </div>
            </div>

            <el-divider class="my-2"/>
          </template>
          <div class="notification__show-more__wrapper">
            <router-localized-link :to="{name : 'notifications'}" @click="$refs.dropdown.handleClose()">
              <span class="notification__show-more me-2" v-t="'notifications.show_all'"/>
              <i class="icon-arrow-3"/>
            </router-localized-link>
          </div>
        </div>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {needAuth} from '@/mixins/auth/needAuth';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink.vue';

export default {
  name: 'notifications-dropdown',
  components: {RouterLocalizedLink},
  mixins: [needAuth],
  computed: {
    ...mapGetters('auth', ['GUEST']),
    ...mapGetters('notifications', ['NOTIFICATIONS']),
    /**
     * Latest notification Id
     * @returns {*|number}
     */
    lastNotificationId() {
      return this.NOTIFICATIONS.length ? this.NOTIFICATIONS[0].id : 0
    },
    /**
     * Notifications Count
     * @returns {*}
     */
    freshNotificationsCount() {
      return _.size(_.filter(this.NOTIFICATIONS, (n) => !n.checked_at))
    },
  },
  methods: {
    ...mapActions('notifications', [
      'READ_NOTIFICATION',
      'CHECK_NOTIFICATIONS',
      'READ_ALL_NOTIFICATIONS',
      'CHECK_ALL_NOTIFICATIONS',
      'DELETE_NOTIFICATION'
    ]),
    /**
     * Show notifications event
     * @param isVisible
     */
    showNotifications(isVisible) {
      if (!isVisible) {
        return;
      }
      if (this.triggerLoginDialog()) {
        return;
      }

      if (this.freshNotificationsCount > 0) {
        this.CHECK_ALL_NOTIFICATIONS()
      }
    },
    /**
     * @param notification
     */
    clickNotification(notification) {
      this.READ_NOTIFICATION(notification.id).then(() => {
        if (notification.is_link && notification.url) {
          window.open(notification.url, '_self');
        }
      })
    }
  }
}
</script>
