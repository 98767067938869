<template>
  <div class="store-mailer m-2">
    <el-divider class="my-3" v-if="dividers"></el-divider>

    <div class="store-container-mailer">
      <div class="store-mailer-header" v-t="'folder.store_mailer_header'"></div>
      <div class="store-logo" v-if="showClientRecommendationsLogo">
        <img :src="CURRENT_STORE.image_url"
            :title="CURRENT_STORE.translation.title"
            alt="">
      </div>
      <div class="store-mailer-text my-3" v-html="$t('folder.store_mailer_text', {store: CURRENT_STORE.translation.title})"></div>
      <div class="store-mailer-switcher my-3">
        <el-switch
            :before-change="beforeSwitch"
            v-model="CURRENT_STORE.is_subscribed"
            :active-text="$t('folder.store_mailer_switcher')">
        </el-switch>
        <i class="icon-emailicon"/>
      </div>
      <div class="store-mailer-footer my-3"
          v-html="$t('folder.store_mailer_footer', {total: subscriptionsCount})">
      </div>

      <el-divider class="my-3" v-if="dividers"></el-divider>
    </div>
  </div>
</template>


<script>
import {mapGetters} from 'vuex';
import {randomSubscriptions} from '@/mixins/store/randomSubscriptions';

export default {
  name: 'store-mailer',
  props: {
    dividers: {
      type: Boolean,
      default: true,
    },
    showClientRecommendationsLogo: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [randomSubscriptions],
  computed: {
    ...mapGetters('store', [
      'CURRENT_STORE',
    ]),
    ...mapGetters('auth', [
      'GUEST',
    ]),
  },
  methods: {
    beforeSwitch() {
      return new Promise((resolve, reject) => {
        if (this.GUEST) {
          this.$dialogs.show('auth', 'default');
          return reject(new Error('Error'));
        }

        if (this.CURRENT_STORE.is_subscribed) {
          this.$store.dispatch('store/REMOVE_SUBSCRIPTION', this.CURRENT_STORE.id);
        } else {
          this.$store.dispatch('store/SET_SUBSCRIPTION', this.CURRENT_STORE.id);
        }

        return reject(new Error('Error'));
      })
    }
  }
}
</script>
