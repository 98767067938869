<template>
  <div class="folder-promo-horizontal">
    <div v-if="showPromoman" class="promo-hunter">
      <img src="@/assets/images/promo_hunter.svg" class="promo-hunter__face w-100 h-100" alt="">
      <img src="@/assets/images/promo_hunter_hands.svg" class="promo-hunter__hands" alt="">
    </div>
    <div
      v-if="isBangPromo"
      class="bang-promo"
      @click="showFullPromo"
      @mouseenter="bangPromoHover = true"
      @mouseleave="bangPromoHover = false"
    />
    <div v-if="upvoteAnimation" class="upvote-animation">
      <img src="@/assets/images/firework.gif" class="w-100 h-100" alt="">
    </div>

    <!--
       Header
    -->
    <div class="folder-promo-horizontal__header">
      <div class="favorite-circle">
        <i
          :class="{'active icon-heart-2': folderPromo.is_favourite, 'icon-heart': !folderPromo.is_favourite}"
          @click="processFavourite"
        />
      </div>
    </div>

    <div class="d-flex border-box">
      <!--
         Image
      -->
      <div class="folder-promo-horizontal__image" v-loading="imageIsLoading">
        <img
          :src="folderPromo.image_url"
          :class="{'hover': bangPromoHover}"
          @load="imageIsLoading = false"
          @error="imageLoadError = true"
          @click="showFullPromo"
          alt=""
        >
      </div>

      <!--
         Content
      -->
      <div class="folder-promo-horizontal__content">
        <div class="w-100 align-self-start">
          <div class="ps-1">
            <div class="price" v-if="folderPromo.new_price || folderPromo.discount">
              <span class="me-1">&#8212;</span>

              <template v-if="folderPromo.new_price">
                <template v-if="folderPromo.old_price">
                  <span class="price--old">€ {{ folderPromo.old_price }}</span>
                  <span class="price--separator"> | </span>
                </template>
                <span class="price--new">€ {{ folderPromo.new_price }}</span>
              </template>

              <span v-else-if="folderPromo.discount" class="price--discount">
                {{ $t('post.discount') }}: {{ folderPromo.discount }}
              </span>

              <span class="ms-1">&#8212;</span>
            </div>

            <p class="title" v-text="folderPromo.title"/>
            <button class="more" @click="showFullPromo">{{ $t('post.meer_detail') }}</button>
          </div>

          <button class="look-promo mx-md-1" @click="showFullPromo">
            {{ $t('post.review_folder') }}
          </button>
        </div>

        <div class="w-100 ps-1 d-flex flex-row flex-md-column justify-content-between border-box">
          <author-info
            class="flex-grow-1 justify-content-start justify-content-md-center"
            :avatar="authorAvatar"
            :name="authorName"
            :profile-id="profileId"
            :profileType="profileType"
            :valid_text="folderPromo.valid_text"
            :is-valid="!folderPromo.is_expired"
          />

          <share btn-class="d-flex align-items-center ps-1" @share="processShare"/>
        </div>
      </div>
    </div>

    <!--
      Footer
    -->
    <div class="folder-promo-mini__footer">
      <votes
        :is-like="folderPromo.is_hit"
        :is-dislike="folderPromo.is_dislike"
        :rating="folderPromo.rating_calculated"
        @like="makeHit"
        @dislike="makeDislike"
      />
    </div>
  </div>
</template>

<script>
import {needAuth} from '@/mixins/auth/needAuth';
import {actions} from '@/mixins/post/actions';
import Votes from '@/components/feed/Votes.vue';
import Share from '@/components/feed/Share.vue';

export default {
  name: 'folder-promo-horizontal',
  components: {Share, Votes},
  mixins: [actions, needAuth],
  props: {
    folderPromo: Object,
    isBangPromo: {
      type: Boolean,
      default: false
    },
    showPromoman: {
      type: Boolean,
      default: false
    },
    vuexModule: {
      type: String,
      default: 'promo',
    },
  },
  data() {
    return {
      imageLoadError: false,
      imageIsLoading: true,
      bangPromoHover: false,
      upvoteAnimation: false,
    }
  },
  computed: {
    /**
     * @returns {any}
     */
    editor() {
      return this.folderPromo.editor;
    },
    /**
     * Avatar url
     * @returns {*}
     */
    authorAvatar() {
      return !this.editor ? this.folderPromo.folderPage.folder.store.image_url : this.editor.avatar_url
    },
    /**
     * Author name
     * @returns {*|string}
     */
    authorName() {
      return !this.editor ? this.storeTitle : this.editor.full_name
    },
    profileId() {
      return !this.editor ? this.folderPromo.folderPage.folder.store.translation.route : String(this.editor.id)
    },
    profileType() {
      return !this.editor ? 'store' : 'user'
    },
    /**
     * Data for mixin
     * @returns {{morphable_type: string, entity: Object}}
     */
    entityData() {
      return {
        entity: this.folderPromo,
        morphable_type: 'FolderItem'
      }
    },
    /**
     * Store title
     * @returns {*|string}
     */
    storeTitle() {
      const folderPage = this.folderPromo.folderPage;

      return folderPage ? folderPage.folder.store.translation.title : ''
    }
  },
  mounted() {
    this.emitter.on('show-folder-promo-preview', (id) => {
      if (this.folderPromo.id === id) {
        this.showFullPromo();
      }
    })
  },
  methods: {
    /**
     * Share
     * @param destination
     */
    processShare(destination) {
      const link = process.env.VUE_APP_URL + '?folder-item=' + this.folderPromo.id;
      this.shareLink(link, destination);
    },
    /**
     * Like
     */
    makeHit() {
      const isHit = this.folderPromo.is_hit;
      const thisElem = this

      this.processHit()?.then(() => {
        thisElem.$emit('voted')

        setTimeout(() => {
          if (!isHit && thisElem.isBangPromo) {
            thisElem.enableUpvoteAnimation()
          }
        }, 200);
      })
    },
    /**
     * Dislike
     */
    makeDislike() {
      const thisElem = this

      this.processDislike()?.then(function () {
        thisElem.$emit('voted')
      })
    },
    enableUpvoteAnimation() {
      this.upvoteAnimation = true;
      setTimeout(() => this.upvoteAnimation = false, 4200)
    },
    showFullPromo() {
      this.$emit('opened')
      this.routerPush({name: 'folder_promos.show', params: {id: String(this.folderPromo.id)}});
    }
  }
}
</script>
