const getDefaultState = () => {
    return {
        brands: {
            all: [],
            promise: null,
        },
        preview_count: 13,
        currentBrand: null
    }
};

export default {
    namespaced: true,
    state: () => (getDefaultState()),

    getters: {
        BRANDS(state) {
            return state.brands.all;
        },
        BRANDS_PROMISE(state) {
            return state.brands.promise;
        },
        BRANDS_PREVIEW_COUNT(state) {
            return state.preview_count;
        },
        CURRENT_BRAND(state) {
            return state.currentBrand;
        }
    },

    actions: {
        FETCH_BRANDS({state, commit, dispatch}, filter) {
            let params = filter;

            // Clean empty values
            params = _.omitBy(params, (i) => {
                return _.isNumber(i) ? i === 0 : _.isEmpty(i);
            });

            return axios.get('/brands', {params}).then((response) => {
                commit('SET_BRANDS', response.data.data);
            });
        },
        async FETCH_CURRENT_BRAND({state, commit, dispatch}, route) {
            return axios.get('/brand/' + route).then((response) => {
                commit('UPDATE_CURRENT_BRAND', response.data.data);
            });
        },

        RESET_BRANDS({commit}) {
            commit('SET_DEFAULT_DATA');
        },
        SET_SUBSCRIPTION({state, commit}, brand_id) {
            return axios.put(`/brands/${brand_id}/subscribe`).then((response) => {
                commit('UPDATE_CURRENT_BRAND', response.data.data);
            });
        },
        REMOVE_SUBSCRIPTION({state, commit}, brand_id) {
            return axios.put(`/brands/${brand_id}/unsubscribe`).then((response) => {
                commit('UPDATE_CURRENT_BRAND', response.data.data);
            });
        },
    },

    mutations: {
        SET_BRANDS(state, data) {
            _.forEach(data, function (brand) {
                if (!_.find(state.brands.all, {id: brand.id})) {
                    state.brands.all = _.union(state.brands.all, [brand]);
                }
            });
        },

        UPDATE_CURRENT_BRAND(state, data) {
            return state.currentBrand = data;
        },

        SET_DEFAULT_DATA(state) {
            return _.assign(state, getDefaultState());
        }
    }
}
