<template>
  <div class="post-mini">
    <div v-if="showPromoman" class="promo-hunter">
      <img src="@/assets/images/promo_hunter.svg" class="promo-hunter__face w-100 h-100" alt="">
      <img src="@/assets/images/promo_hunter_hands.svg" class="promo-hunter__hands" alt="">
    </div>
    <div
      v-if="isBangPromo"
      class="bang-promo"
      @click="showFullPromo"
      @mouseenter="bangPromoHover = true"
      @mouseleave="bangPromoHover = false"
    />
    <div v-if="upvoteAnimation" class="upvote-animation">
      <img src="@/assets/images/firework.gif" class="w-100 h-100" alt="">
    </div>

    <!--
      Header
    -->
    <div class="post-mini__header">
      <div class="favorite-circle">
        <i
          :class="{'active icon-heart-2': post.is_favourite, 'icon-heart': !post.is_favourite}"
          @click="processFavourite"
        />
      </div>
    </div>

    <!--
       Image
    -->
    <div class="post-mini__image" v-loading="imageIsLoading">
      <img
        :src="(imageLink && !imageLoadError) ? imageLink : require('@/assets/images/promo_code.jpg')"
        :class="{'hover': bangPromoHover}"
        @load="imageIsLoading = false"
        @error="imageLoadError = true"
        @click="showFullPromo"
        alt=""
      />
    </div>

    <!--
       Content
    -->
    <div class="post-mini__content">
      <div class="d-flex flex-column align-items-center">
        <div class="price" v-if="post.new_price || post.discount || post.discount_text">
          <span class="hyphen me-1">&#8212;</span>

          <template v-if="post.new_price">
            <template v-if="post.old_price">
              <span class="price--old">€ {{ post.old_price }}</span>
              <span class="price--separator"> | </span>
            </template>
            <span class="price--new">€ {{ post.new_price }}</span>
          </template>

          <span v-else-if="post.discount" class="price--discount">{{ $t('post.discount') }}: {{ post.discount }}</span>
          <span v-else-if="post.discount_text" class="price--discount">{{ post.discount_text }}</span>

          <span class="hyphen ms-1">&#8212;</span>
        </div>

        <p v-if="title" class="title w-100 cursor-pointer" v-html="title" @click="showFullPromo"/>
        <button class="more" @click="showFullPromo">{{ $t('post.meer_detail') }}</button>
      </div>

      <div class="w-100 d-flex justify-content-center align-items-center">
        <button class="look-promo mx-md-1 w-100" @click="btnClicked">
          {{ $t(discountCode[hasDiscountCode]) }}
        </button>
      </div>
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="author-info-container">
          <div class="w-100 d-flex justify-content-between">
            <author-info
              class="flex-grow-1 justify-content-start justify-content-md-center"
              :avatar="post.user?.avatar_url ?? require('@/assets/images/fb_logo.png')"
              :name="post.user?.full_name ?? 'Promojager'"
              :profile-id="post.user?.id"
              :profileType="'user'"
              :valid_text="post.valid_text"
              :is-valid="!post.is_expired"
            />
          </div>
        </div>
      </div>
    </div>

    <!--
      Footer
    -->
    <div class="post-mini__footer">
        <votes
          :is-like="post.is_hit"
          :is-dislike="post.is_dislike"
          :rating="post.rating_calculated"
          @like="makeHit"
          @dislike="makeDislike"
        />
    </div>
  </div>
</template>

<script>
import post from '@/plugins/post';
import {needAuth} from '@/mixins/auth/needAuth';
import {actions} from '@/mixins/post/actions';
import {share} from '@/mixins/post/share';
import Votes from '@/components/feed/Votes.vue';

export default {
  name: 'post-mini',
  components: {Votes},
  mixins: [actions, needAuth, share],
  props: {
    post: Object,
    isBangPromo: {
      type: Boolean,
      default: false
    },
    showPromoman: {
      type: Boolean,
      default: false
    },
    vuexModule: {
      type: String,
      default: 'promo',
    },
  },
  data() {
    return {
      imageLoadError: false,
      imageIsLoading: true,
      bangPromoHover: false,
      upvoteAnimation: false,
      discountCode: ['post.look_promo', 'post.discount_btn', 'post.go_webshop_btn']
    }
  },
  computed: {
    imageLink() {
      if (this.post.images.length) {
        return this.post.images[0].src;
      }
      if (this.post.webshop) {
        return this.post.webshop.image_url;
      }
      return null;
    },
    /**
     * Data for mixin
     * @returns {{morphable_type: string, entity: Object}}
     */
    entityData() {
      return {
        entity: this.post,
        morphable_type: 'Post'
      }
    },
    /**
     * Post title
     * @returns {*|string}
     */
    title() {
      return post.getTitle(this.post);
    },
    hasDiscountCode() {
      let discountCode = 0;

      if (this.post.discount_code || this.post.discount_codes_count) {
        discountCode = 1;
      } else {
        if (_.get(this.post, 'webshops[0].translation.affiliate_url', null)) {
          discountCode = 2;
        }
      }

      return discountCode;
    },
  },
  methods: {
    processShare(destination) {
      const link = process.env.VUE_APP_URL + '?post=' + this.post.id;
      this.shareLink(link, destination);
    },
    makeHit() {
      const isHit = this.post.is_hit;
      const thisElem = this

      this.processHit()?.then(() => {
        thisElem.$emit('voted')

        setTimeout(() => {
          if (!isHit && thisElem.isBangPromo) {
            thisElem.enableUpvoteAnimation()
          }
        }, 200);
      })
    },
    makeDislike() {
      const thisElem = this

      this.processDislike()?.then(function () {
        thisElem.$emit('voted')
      })
    },
    enableUpvoteAnimation() {
      this.upvoteAnimation = true;
      setTimeout(() => this.upvoteAnimation = false, 4200)
    },
    btnClicked() {
      if (this.hasDiscountCode !== 0) {
        this.openDiscount()
      } else {
        this.showFullPromo()
      }
    },
    showFullPromo() {
      this.$emit('opened')
      this.routerPush({name: 'posts.show', params: {id: String(this.post.id)}});
    },
    openDiscount() {
      if (this.hasDiscountCode === 1) {
        this.emitter.emit('copy-discount-code', {
          discount_url: this.post.discount_url,
          discount_code: this.post.discount_code,
          id: this.post.id
        });
        return;
      }

      if (this.hasDiscountCode === 2) {
        return window.open(_.get(this.post, 'webshops[0].translation.affiliate_url', null), '_blank');
      }

      this.axios.put('/posts/' + this.post.id + '/discount-code').then((response) => {
        this.emitter.emit('copy-discount-code', {
          discount_url: this.post.discount_url,
          discount_code: response.data.data.discount_code,
          id: this.post.id
        });
      }).catch((e) => {
        if (404 === e.response.status) {
          alert('No available codes');
        }
      });
    }
  }
}
</script>
