<template>
  <base-filter ref="filter"
               :filter-label="$t('filters.webshops')"
               :filter-options="filterOptions"
               :default-checked="defaultFilterValue"
               filter-key="webshops_filter"
               @filter="filter">
  </base-filter>
</template>

<script>
import BaseFilter from '@/components/left_bar/filters/BaseFilter';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'webshops-filter',
  components: {BaseFilter},
  props: {
    webshops: {
      type: Array,
      default() {
        return []
      }
    },
  },
  computed: {
    ...mapGetters('promo', [
      'PROMOS_WEBSHOPS',
    ]),

    filterOptions() {
      const resultOptions = {};
      const webshops = this.PROMOS_WEBSHOPS.length ? this.PROMOS_WEBSHOPS : this.webshops;

      _.forEach(webshops, function (webshop) {
        resultOptions[webshop.id] = webshop.translation.title;
      });

      return resultOptions;
    },
    webshopsFilter() {
      return _.get(this.$router.currentRoute.value.query, 'webshops_filter[]')
    },
    defaultFilterValue() {
      return _.castArray(this.webshopsFilter)
    }
  },
  mounted() {
    if (!_.isEmpty(this.webshopsFilter)) {
      this.$refs.filter.setCheckedList(this.defaultFilterValue);
    }
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_VALUE',
    ]),

    filter(checked) {
      this.SET_FILTER_VALUE({webshops_filter: checked});
    }
  }
}
</script>
