<template>
  <base-filter ref="filter"
               :filter-label="$t('filters.categories')"
               :filter-options="filterOptions"
               :default-checked="defaultFilterValue"
               filter-key="categories_filter"
               @filter="filter">
  </base-filter>
</template>

<script>
import BaseFilter from '@/components/left_bar/filters/BaseFilter';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'categories-filter',
  components: {BaseFilter},
  props: {
    categories: {
      type: Array,
      default() {
        return []
      }
    },
  },
  computed: {
    ...mapGetters('promo', [
      'PROMOS_CATEGORIES',
    ]),
    ...mapGetters('filter', [
      'FILTERS_VALUES',
    ]),
    filterOptions() {
      let optionsSource = this.PROMOS_CATEGORIES.length ? this.PROMOS_CATEGORIES : this.categories;
      if (this.$route.name === 'folders') {
        if (this.FILTERS_VALUES.stores_filter.length || this.FILTERS_VALUES.categories_filter.length) {
          optionsSource = this.$store.getters['folder/FOLDERS_CATEGORIES'];
        } else {
          const foldersCategories = this.$store.getters['folder-popular/FOLDERS_CATEGORIES']

          if (foldersCategories.length) {
            optionsSource = this.$store.getters['folder-popular/FOLDERS_CATEGORIES'];
          }
        }
      }

      const resultOptions = {};
      _.forEach(optionsSource, function (category) {
        resultOptions[category.id] = category.translation.title;
      });
      return resultOptions;
    },
    categoriesFilter() {
      return _.get(this.$router.currentRoute.value.query, 'categories_filter[]')
    },
    defaultFilterValue() {
      return _.castArray(this.categoriesFilter)
    }
  },
  mounted() {
    if (!_.isEmpty(this.categoriesFilter)) {
      this.$refs.filter.setCheckedList(this.defaultFilterValue);
    }
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_VALUE',
    ]),

    filter(checked) {
      this.SET_FILTER_VALUE({categories_filter: checked});
    },
  }
}
</script>
