<template>
  <div class="email-subscription-section">
    <default-space>
      <div class="d-inline-flex w-100">
        <el-form ref="form" :model="form" :rules="rules">
          <h3 class="email-subscription-section__title" v-t="'landing.email_subscription.title'"/>
          <p class="email-subscription-section__subtitle" v-t="'landing.email_subscription.subtitle'"/>
          <el-form-item prop="email">
            <el-input
              v-model="form.email"
              type="email"
              :placeholder="$t('landing.email_subscription.input.placeholder')"
            >
              <template #append>
                <el-button v-t="'landing.email_subscription.input.submit'" @click="submit"/>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
        <div class="email-subscription-section__image-wrapper">
          <img src="@/assets/images/landing/promoman.svg" alt="">
        </div>
      </div>
    </default-space>
    <div class="half-circle-wrapper">
      <div class="half-circle">
        <img src="@/assets/images/landing/check.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import DefaultSpace from '@/views/DefaultSpace.vue';
import {mapActions} from 'vuex';

export default {
  name: 'EmailSubscriptionSection',
  components: {DefaultSpace},
  data() {
    return {
      form: {
        email: ''
      },
      rules: {
        email: [{required: true, validator: this.backendValidator}],
      }
    }
  },
  methods: {
    ...mapActions('loader', ['SET_LOADER']),
    /**
     * On submit
     * @returns {Promise<any>}
     */
    async submit() {
      this.SET_LOADER(true);

      return await axios.post('/newsletter-subscriptions', {email: this.form.email})
        .then((r) => {
          this.routerPush({path: '/newsletter-subscription'});
        })
        .catch((e) => {
          const errorMsg = _.get(e, 'response.data.errors.email[0]') || _.get(e, 'response.data.error')

          this.$notify.error({
            title: this.$t('notifications.default.title'),
            message: errorMsg || this.$t('notifications.default.message'),
            position: this.notificationPosition,
            duration: this.notificationDuration,
          });
        })
        .then(() => this.SET_LOADER(false));
    }
  }
}
</script>
