<template>
  <base-filter
    ref="filter"
    filter-key="promos_filter"
    :filter-label="$t('filters.post')"
    :filter-options="filterOptions"
    :default-checked="defaultFilterValue"
    :short-length="4"
    custom-order
    @filter="filter"
  />
</template>

<script>
import BaseFilter from '@/components/left_bar/filters/BaseFilter';
import {mapActions} from 'vuex';

export default {
  name: 'promos-filter',
  components: {BaseFilter},
  computed: {
    promosFilter() {
      return _.get(this.$router.currentRoute.value.query, 'promos_filter[]')
    },
    defaultFilterValue() {
      return _.castArray(this.promosFilter)
    }
  },
  data() {
    return {
      filterOptions: {
        'day_deals': this.$t('post.promos_filter.day_deals'),
        'folder_promos': this.$t('post.promos_filter.folder_promos'),
        'promos': this.$t('post.promos_filter.promos'),
        'discount_code': this.$t('post.promos_filter.discount_code'),
        'post_cashback': this.$t('post.promos_filter.post_cashback'),
        'post_ecoupon': this.$t('post.promos_filter.post_ecoupon'),
        'post_free_products': this.$t('post.promos_filter.post_free_products'),
        'post_sale': this.$t('post.promos_filter.post_sale'),
      },
    };
  },
  mounted() {
    if (!_.isEmpty(this.promosFilter)) {
      this.$refs.filter.setCheckedList(_.castArray(this.defaultFilterValue));
    }
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_VALUE',
    ]),

    filter(checked) {
      this.SET_FILTER_VALUE({promos_filter: checked});
    }
  }
}
</script>
