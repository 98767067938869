<template>
  <div class="categories-filter">
    <default-space ref="scrollbarContainer">
      <el-scrollbar
        ref="scrollbar"
        :native="false"
        @scroll="onScroll"
        @touchstart="handleTouchStart"
        @touchmove.prevent="handleTouchMove"
        @touchend="handleTouchEnd"
      >
        <!--
            Arrows
        -->
        <button class="categories-filter__arrow--start" @click="scrollPrev">
          <i class="icon-triangle-arrow"/>
        </button>
        <button class="categories-filter__arrow--end" @click="scrollNext">
          <i class="icon-triangle-arrow"/>
        </button>
        <!--
           Options
        -->
        <div class="el-scrollbar__options" ref="innerRef" :style="customGapStyle">
          <div
            v-for="(category, i) in options"
            class="category-option-wrapper category-option-wrapper-original"
            :key="i"
          >
            <router-localized-link
              :to="{name : 'promos'}"
              :params="category.id ? {ch_filter: [category.id], ...searchQuery} : {}"
              class="category-option"
              :class="{'category-option__active': category.id === activeCategory || category.isActive}"
              @click="onClick(category)"
            >
              <i :class="`icon-${category.icon} mb-1`"/>
              {{ category.translation.simplified_title }}
            </router-localized-link>
          </div>
        </div>
      </el-scrollbar>
    </default-space>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink.vue';
import DefaultSpace from '@/views/DefaultSpace.vue';

export default {
  name: 'categories-header-filter',
  components: {DefaultSpace, RouterLocalizedLink},
  computed: {
    ...mapGetters('category', ['CATEGORIES']),
    ...mapGetters('promo', [
      'PROMOS_CATEGORIES',
    ]),
    ...mapGetters('filter', [
      'FILTERS_VALUES',
    ]),
    /**
     * Filtered Categories
     * @returns {*}
     */
    categories() {
      return this.CATEGORIES.filter((c) => c.translation?.simplified_title)
    },
    searchQuery() {
      const q = this.$route.query.q
      const search_filter = this.$route.query.search_filter
      const tab = this.$route.query.tab

      const result = {}

      if (search_filter) {
        result.search_filter = search_filter
      }

      if (q) {
        result.q = q
      }

      if (tab) {
        result.tab = tab
      }

      return result
    },
    /**
     * Categories + All
     * @returns {*}
     */
    options() {
      return _.union(
        [{
          icon: 'all',
          isActive: this.isAllActive,
          translation: {simplified_title: this.$t('filters.all')}
        }],
        this.categories,
      )
    },
    /**
     * Active category ID
     * @returns {*|string}
     */
    activeCategory() {
      const value = _.get(this.$router.currentRoute.value.query, 'ch_filter[]', [])

      return value.length ? Number(value) : 0
    },
    /**
     * Is All option active
     * @returns {boolean}
     */
    isAllActive() {
      return this.activeCategory === 0 && this.$route.name === 'promos'
    },
    /**
     * Options gap counting
     * @returns {number}
     */
    gap() {
      const restOfPixels = this.previewWidth % this.optionWidth
      const fullVisibleOptions = Math.floor(this.previewWidth / this.optionWidth) - 1

      return restOfPixels / fullVisibleOptions
    },
    /**
     * Gap style
     * @returns {string|string}
     */
    customGapStyle() {
      return this.gap ? `gap: ${this.gap}px` : ''
    },
    /**
     * scroll offset
     * @returns {number}
     */
    offset() {
      return this.gap + this.optionWidth
    },
    /**
     * How many steps should be scrolled
     * @returns {number|number}
     */
    scrollSteps() {
      return this.xsOnly ? this.optionsInPreviewCount : Math.floor(this.optionsInPreviewCount / 2)
    },
    /**
     * Amount of displayed options in preview
     * @returns {number}
     */
    optionsInPreviewCount() {
      return Math.floor(this.optionsContainer.offsetWidth / this.optionWidth)
    },
    /**
     * Container with category options
     * @returns {Element}
     */
    optionsContainer() {
      return document.querySelector('.el-scrollbar__options');
    }
  },
  data() {
    return {
      previewWidth: 0,
      optionWidth: 0,
      currentScroll: 0,
      currentScrollPx: 0,
    }
  },
  mounted() {
    if (!this.CATEGORIES.length) {
      this.FETCH_CATEGORIES()
    }

    this.previewWidth = this.$refs.innerRef ? this.$refs.innerRef.clientWidth : 0;
    this.optionWidth = this.$refs.innerRef && this.$refs.innerRef.children.length ? this.$refs.innerRef.children[0].clientWidth : 0;
  },
  methods: {
    ...mapActions('category', ['FETCH_CATEGORIES']),
    ...mapActions('filter', [
      'SET_FILTER_VALUE',
    ]),
    ...mapMutations('filter', [
      'UPDATE_FILTER_VALUE',
    ]),
    handleTouchStart(event) {
      this.startX = event.touches[0].clientX;
    },
    handleTouchMove(event) {
      this.currentX = event.touches[0].clientX;
    },
    handleTouchEnd() {
      if (this.startX - this.currentX > 20) {
        this.scrollNext()
      } else if (this.currentX - this.startX > 20) {
        this.scrollPrev()
      }
    },
    /**
     * @param category
     * @returns {boolean}
     */
    isActiveCategory(category) {
      return category.id === this.activeCategory
    },
    onClick(category) {
      if (this.isActiveCategory(category)) {
        this.filter([])
      } else {
        this.filter(category.id ? [category.id] : [])
      }
    },
    filter(checked) {
      if (this.$route.name === 'promos') {
        this.SET_FILTER_VALUE({ch_filter: checked});
      } else {
        this.UPDATE_FILTER_VALUE({ch_filter: checked});
      }
    },
    /**
     * Scroll event
     * @param value
     */
    onScroll(value) {
      this.currentScrollPx = value.scrollLeft;
    },
    scrollNext() {
      const options = document.querySelectorAll('.el-scrollbar__options .category-option-wrapper-original');
      const scrollWidth = this.optionsContainer.scrollWidth - this.optionsContainer.offsetWidth;

      if (this.currentScrollPx + this.offset >= scrollWidth) {
        options.forEach((el) => {
          const clone = el.cloneNode(true);
          clone.classList.remove('category-option-wrapper-original');
          this.optionsContainer.appendChild(clone);
        })
      }

      this.scroll(this.scrollSteps);
    },
    scrollPrev() {
      const wrap = document.querySelector('.el-scrollbar__wrap');

      if (this.currentScrollPx - this.offset < 0) {
        wrap.style.scrollBehavior = 'auto'

        const allElem = document.querySelector('.el-scrollbar__options .category-option-wrapper');

        let nextElement = allElem.nextElementSibling

        for (let i = 1; i < this.categories.length; i++) {
          nextElement = nextElement.nextElementSibling;
        }

        this.$refs.scrollbar.setScrollLeft(this.currentScrollPx + this.offset);
        const clone = nextElement.cloneNode(true);
        clone.classList.remove('category-option-wrapper-original');
        this.optionsContainer.insertBefore(clone, allElem);
        wrap.style.scrollBehavior = 'smooth'
      }

      this.scroll(-this.scrollSteps);
    },
    scroll(value) {
      if (this.currentScroll + value < 0) {
        this.currentScroll = 0
      } else {
        this.currentScroll += value
      }

      this.currentScrollPx = this.offset * this.currentScroll;
      this.$refs.scrollbar.setScrollLeft(this.currentScrollPx);
    },
  },
}
</script>
