<template>
  <div class="online-promo-mini">
    <div v-if="showPromoman" class="promo-hunter">
      <img src="@/assets/images/promo_hunter.svg" class="promo-hunter__face w-100 h-100" alt="">
      <img src="@/assets/images/promo_hunter_hands.svg" class="promo-hunter__hands" alt="">
    </div>
    <div
      v-if="isBangPromo"
      class="bang-promo"
      @click="showFullPromo"
      @mouseenter="bangPromoHover = true"
      @mouseleave="bangPromoHover = false"
    />
    <div v-if="upvoteAnimation" class="upvote-animation">
      <img src="@/assets/images/firework.gif" class="w-100 h-100" alt="">
    </div>

    <!--
       Header
    -->
    <div class="online-promo-mini__header">
      <div class="favorite-circle">
        <i
            :class="{'active icon-heart-2': onlinePromo.is_favourite, 'icon-heart': !onlinePromo.is_favourite}"
            @click="processFavourite"
        />
      </div>
    </div>

    <!--
       Image
    -->
    <div class="online-promo-mini__image" v-loading="imageIsLoading">
      <img
        :src="imageUrl"
        :class="{'hover': bangPromoHover}"
        @load="imageIsLoading = false"
        @click="showFullPromo"
        @error="imageLoadError = true"
        alt=""
      />
    </div>

    <!--
       Content
    -->
    <div class="online-promo-mini__content">
      <div class="price" v-if="onlinePromo.new_price || onlinePromo.discount">
        <span class="hyphen me-1">&#8212;</span>

        <template v-if="onlinePromo.new_price">
          <template v-if="onlinePromo.old_price">
            <span class="price--old">€ {{ onlinePromo.old_price }}</span>
            <span class="price--separator"> | </span>
          </template>
          <span class="price--new">€ {{ onlinePromo.new_price }}</span>
        </template>
        <span v-else-if="onlinePromo.discount">
          {{ onlinePromo.discount }} %
        </span>

        <span class="hyphen ms-1">&#8212;</span>
      </div>

      <div class="px-md-1 d-flex flex-column align-items-center">
        <p class="title" v-html="onlinePromo.title" @click="showFullPromo"/>
        <button class="more" @click="showFullPromo">{{ $t('post.meer_detail') }}</button>
      </div>

      <div class="w-100 d-flex justify-content-center align-items-center">
        <button class="look-promo mx-md-1 w-100" @click="openInNewTab">
          {{ $t('post.look_promo') }}
        </button>
      </div>

      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="author-info-container">
          <div class="w-100 d-flex justify-content-between">
            <author-info
              class="flex-grow-1 justify-content-start justify-content-md-center"
              :avatar="authorAvatar"
              :name="authorName"
              :profile-id="profileId"
              :profileType="profileType"
              :valid_text="onlinePromo.valid_text"
              :is-valid="!onlinePromo.is_expired"
            />
          </div>
        </div>
      </div>
    </div>

    <!--
        Footer
     -->
    <div class="online-promo-mini__footer">
      <votes
          :is-like="onlinePromo.is_hit"
          :is-dislike="onlinePromo.is_dislike"
          :rating="onlinePromo.rating_calculated"
          @like="makeHit"
          @dislike="makeDislike"
      />
    </div>
  </div>
</template>

<script>
import {needAuth} from '@/mixins/auth/needAuth';
import {actions} from '@/mixins/post/actions';
import Votes from '@/components/feed/Votes.vue';

export default {
  name: 'online-promo-mini',
  components: {Votes},
  mixins: [actions, needAuth],
  props: {
    onlinePromo: Object,
    isBangPromo: {
      type: Boolean,
      default: false
    },
    showPromoman: {
      type: Boolean,
      default: false
    },
    vuexModule: {
      type: String,
      default: 'promo',
    },
  },
  data() {
    return {
      imageLoadError: false,
      imageIsLoading: true,
      bangPromoHover: false,
      upvoteAnimation: false,
    }
  },
  computed: {
    /**
     * @returns {any}
     */
    editor() {
      return this.onlinePromo.editor;
    },
    authorAvatar() {
      return !this.editor ? this.onlinePromo.webshop.image_url : this.editor.avatar_url
    },
    authorName() {
      return !this.editor ? this.onlinePromo.webshop.translation.title : this.editor.full_name
    },
    profileId() {
      return !this.editor ? this.onlinePromo.webshop.translation.route : String(this.editor.id)
    },
    profileType() {
      return !this.editor ? 'webshop' : 'user'
    },
    /**
     * Data for mixin
     * @returns {{morphable_type: string, entity: Object}}
     */
    entityData() {
      return {
        entity: this.onlinePromo,
        morphable_type: 'OnlinePromo'
      }
    },
    /**
     * Image url
     * @returns {*}
     */
    imageUrl() {
      return !this.imageLoadError ? this.onlinePromo.image_url : require('@/assets/images/promo.png')
    }
  },
  methods: {
    openInNewTab() {
      window.open(this.onlinePromo.url, '_blank');
    },
    processShare(destination) {
      const link = process.env.VUE_APP_URL + '?online-promo=' + this.onlinePromo.id;
      this.shareLink(link, destination);
    },
    makeHit() {
      const isHit = this.onlinePromo.is_hit;
      const thisElem = this

      this.processHit()?.then(() => {
        thisElem.$emit('voted')

        setTimeout(() => {
          if (!isHit && thisElem.isBangPromo) {
            thisElem.enableUpvoteAnimation()
          }
        }, 200);
      })
    },
    enableUpvoteAnimation() {
      this.upvoteAnimation = true;
      setTimeout(() => this.upvoteAnimation = false, 4200)
    },
    makeDislike() {
      const thisElem = this

      this.processDislike()?.then(function () {
        thisElem.$emit('voted')
      })
    },
    showFullPromo() {
      this.$emit('opened')
      this.routerPush({name: 'online_promos.show', params: {id: String(this.onlinePromo.id)}});
    }
  }
}
</script>
