export const discountCodes = {
    mounted() {
        this.emitter.on('copy-discount-code', (data) => {
            this.copyDiscountCode(data);
        });
    },
    methods: {
        copyDiscountCode(post) {
            this.$copyText(post.discount_code, undefined, (error, event) => {
                setTimeout(() => {
                    if (post.discount_url) {
                        if (!this.webview) {
                            localStorage.setItem('CopiedDiscountCodeId', post.id);
                            window.open(window.location.href, '_blank');
                            window.open(post.discount_url, '_self');
                            return;
                        }

                        window.open(post.discount_url, '_blank');
                        this.notifyDiscountCodeCopied(post.discount_code);
                        return;
                    }

                    this.notifyDiscountCodeCopied(post.discount_code);
                }, 1500)
            });
        },
        notifyDiscountCodeCopied(discountCode) {
            this.$notify.success({
                title: this.$t('post.notifications.copied.title'),
                message: this.$t('post.notifications.copied.content', {text: discountCode}),
                position: this.notificationPosition,
                duration: this.notificationDuration,
            });
        }
    }
};
